.sign-in {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: url('./assets/cyt-background.jpg');
  background-size: cover;
  text-align: center;
  color: white;

  & .content {
    position: absolute;
    width: 360px;
    height: 72px;
    top: 50%;
    left: 50%;
    margin-top: -136px;
    margin-left: -180px;

    & h2 {
      font-size: 40px;
      font-weight: 700;
    }

    & span {
      font-size: 16px;
      font-weight: 400;
    }
  }
}