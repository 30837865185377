@mixin message($color) {
    max-width: 30rem;
    padding: .5em;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 1em;
    box-shadow: 0 0 1em #ccc;
    background-color: $color;
}

.conversation {
    max-height: 400px;
    overflow: auto;
    padding: .5em;
    background: #f3f3f3;
    border: 1px solid #ffd936;
    border-radius: 1em;

    .message-user {
        @include message(#e6e6e6);
    }

    .message-student {
        @include message(#ffd936);
    }
}
